.container {
  padding-top: 10px;
  padding-bottom: 10px;
};

.thumb {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #3399ff;
  outline: none;
};

.track {
  margin-top: 6px;
  border-radius: 100px;
  height: 4px;
};

