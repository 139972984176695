.container {
  height: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.height100p {
  height: 100%;
}

.width100p {
  width: 100%;
}

.chatContainer {
  display: flex;
  flex-direction: column;
}