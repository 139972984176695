.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.messageList {
  flex-grow: 1;
  padding-top: 20px;
  padding-bottom: 20px;
}
