.container {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
}

.message {
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 5px;
}
.messageSend {
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-left: 15%;
  margin-right: 8px;
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  object-fit: cover;
  object-position: center;
}

.content {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 3px;
}
.contentReceive {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: rgb(240, 240, 240);
  margin-right: 15%;
}
.firstMessageReceive {
  border-top-left-radius: 15px;
}
.lastMessageReceive {
  border-bottom-left-radius: 15px;
}
.contentSend {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: rgb(0, 132, 255);
  margin-right: 8px;
}
.firstMessageSend {
  border-top-right-radius: 15px;
}
.lastMessageSend {
  border-bottom-right-radius: 15px;
}

.textReceive {
  width: 100%;
  color: rgb(0, 0, 0);
  word-break: break-all;
}
.textSend {
  width: 100%;
  color: rgb(255, 255, 255);
  word-break: break-all;
}

.timeReceive {
  font-size: 10px;
  color: rgb(170, 170, 170);
}
.timeSend {
  font-size: 10px;
  color: rgb(255, 255, 255);
}

.date {
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-align: center;
  color: rgb(190, 190, 190);
  font-weight: 600;
  font-size: 12px;
}