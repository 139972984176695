.container {
  width: 100%;
  margin-right: 15px;
  margin-bottom: 30px;
  box-shadow: 10px 10px 5px #aaaaaa;

  @media(min-width: 780px) {
    width: calc(50% - 15px);
  }

  @media(min-width: 1220px) {
    width: calc(33.333% - 15px);
  }
}

.searchTripContainer {
  width: 100%;
  margin-right: 15px;
  margin-bottom: 30px;
  box-shadow: 10px 10px 5px #aaaaaa;

  @media (max-width: 780px) {
    width: 100% !important;
  }

  @media (max-width: 990px) {
    width: calc(50% - 15px);
  }

  @media(min-width: 1560px) {
    width: calc(50% - 15px);
  }

  @media(min-width: 2440px) {
    width: calc(33.333% - 15px);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  background-color: #3977E3;
  height: 39px;
  padding: 0px 10px;
  padding-left: 145px;
  text-align: right;
}

.avatar {
  width: 130px;
  height: 130px;
  object-fit: cover;
  object-position: center;
  padding: 5px;
  background-color: white;
  margin-top: -33px;
  margin-left: 6px;
}

.driverName {
  color: #203A69;
  font-size: 15px;
  text-align: center;
}

.top {
  display: flex;
}

.mainInfo {
  flex-grow: 1;
  padding: 15px 10px;
}

.eventName {
  font-size: 17px;
  color: #566F89;
}

.seatsLabel {
  color: #929292;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event {
  padding: 3px 8px;
  border-radius: 100px;
  font-size: 11px;
  color: white;
}

.active-label {
  background-color: #5cb85c;
}

.exp-label {
  background-color: #d9534f;
}

.cancel-label {
  background-color: #eea236;
}

.mt5 {
  margin-top: 5px;
}

.tripInfo {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.tripItem {
  max-width: 33%;
}

.tripLabel {
  font-size: 13px;
  color: #929292;
}

.tripValue {
  font-size: 13px;
  color: #203B68;
}

.bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: solid 1px lightgray;

  &>div {
    flex: 1;
    text-align: center;
    font-size: 13px;
    padding: 10px 5px;
    color: #929292
  }
}