@media (min-width: 1455px) {
    .my-trips .trips-container .trip-box {
        max-width: calc(33.3% - 30px) !important;
    }
}

@media (min-width: 1625px) {
    .my-trips .trips-container .search {
        max-width: calc(50% - 30px) !important;
    }
}

@media (max-width: 1624px) {
    .my-trips .trips-container .search {
        max-width: calc(100% - 30px) !important;
    }
}